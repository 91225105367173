import React, { useState, useEffect } from "react";

function useNavigatorOffline() {
  let [value, setValue] = useState(!window.navigator.onLine);

  useEffect(() => {
    function handleStatusChange() {
      setValue(!window.navigator.onLine);
    }

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  return value;
}

type Props = {
  agencyName: string;
  url: string;
  smUrl: string | null;
};

export default function Logo({ url, smUrl, agencyName }: Props) {
  const isOffline = useNavigatorOffline();

  return (
    <>
      <img
        src={url}
        alt={`${agencyName} logo`}
        className={`object-scale-down object-left h-full ${
          isOffline ? "offline" : ""
        } ${smUrl ? "hidden lg:block" : ""}`}
      />
      {smUrl && (
        <img
          src={smUrl}
          alt={`${agencyName} logo`}
          className={`lg:hidden object-scale-down object-left h-full ${
            isOffline ? "offline" : ""
          }`}
        />
      )}
    </>
  );
}
