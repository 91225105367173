/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query ServerRelease {\n    release\n    agencies {\n      name\n    }\n  }\n": types.ServerReleaseDocument,
    "\n  query CurrentTime {\n    agencies {\n      currentTime\n    }\n  }\n": types.CurrentTimeDocument,
    "\n  query EmergencyAlert {\n    emergencyAlert {\n      title\n      level\n      message\n    }\n  }\n": types.EmergencyAlertDocument,
    "\n  query GetArrivals($station: ID, $stops: [ID]!, $limit: Int) {\n    arrivals(filter: { stops: $stops, station: $station }, limit: $limit) {\n      stop {\n        id\n        platformCode\n        alerts {\n          id\n          header\n          body\n        }\n      }\n      route {\n        id\n        shortName\n        longName\n        alerts {\n          id\n          header\n          body\n        }\n      }\n      trip {\n        id\n        headsign\n        alerts {\n          id\n          header\n          body\n        }\n      }\n      type: typeAsString\n      sortKey: departure\n      vehicle {\n        id\n        occupancyStatus\n      }\n    }\n  }\n": types.GetArrivalsDocument,
    "\n  query allTripsForRoute($route: String!, $forDate: String) {\n    routes(filter: { shortNameIn: [$route] }) {\n      shortName\n      longName\n      trips(forDate: $forDate) {\n        id\n        headsign\n        lastStop {\n          stop {\n            name\n          }\n        }\n        stops {\n          stop {\n            id\n            name\n          }\n          departure\n          departureDeltaFromScheduledSeconds\n          type\n          stopSequence\n        }\n      }\n    }\n  }\n": types.AllTripsForRouteDocument,
    "\n  query ConstructPageTitle($requestedStops: [ID]!) {\n    stops(filter: { idIn: $requestedStops }) {\n      id\n      name\n    }\n    stations(filter: { idIn: $requestedStops }) {\n      name\n    }\n  }\n": types.ConstructPageTitleDocument,
    "\n  query allTripsForStop($stop: ID, $forDate: String) {\n    stops(filter: { idIn: [$stop] }) {\n      name\n      arrivals(lookbackSeconds: 86400, limit: 0, forDate: $forDate) {\n        trip {\n          id\n          headsign\n          stops {\n            stop {\n              name\n              id\n            }\n            departure\n            type\n          }\n        }\n        route {\n          shortName\n          longName\n        }\n        departure\n        departureDeltaFromScheduledSeconds\n        type\n      }\n    }\n  }\n": types.AllTripsForStopDocument,
    "\n  query GetWeather {\n    weather {\n      temp\n      description\n    }\n  }\n": types.GetWeatherDocument,
    "\n  query dateRangeForSchedule {\n    agencies {\n      schedule {\n        fromDate\n        untilDate\n      }\n    }\n  }\n": types.DateRangeForScheduleDocument,
    "\n  query allRoutes {\n    routes(limit: 0) {\n      shortName\n      longName\n    }\n  }\n": types.AllRoutesDocument,
    "\n  query GetAllStopsAndStations {\n    stops(limit: 10000) {\n      value: id\n      label: name\n      location {\n        latitude\n        longitude\n      }\n      parentStation\n    }\n    stations(limit: 10000) {\n      value: id\n      label: name\n      location {\n        latitude\n        longitude\n      }\n      parentStation\n    }\n  }\n": types.GetAllStopsAndStationsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ServerRelease {\n    release\n    agencies {\n      name\n    }\n  }\n"): (typeof documents)["\n  query ServerRelease {\n    release\n    agencies {\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CurrentTime {\n    agencies {\n      currentTime\n    }\n  }\n"): (typeof documents)["\n  query CurrentTime {\n    agencies {\n      currentTime\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EmergencyAlert {\n    emergencyAlert {\n      title\n      level\n      message\n    }\n  }\n"): (typeof documents)["\n  query EmergencyAlert {\n    emergencyAlert {\n      title\n      level\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetArrivals($station: ID, $stops: [ID]!, $limit: Int) {\n    arrivals(filter: { stops: $stops, station: $station }, limit: $limit) {\n      stop {\n        id\n        platformCode\n        alerts {\n          id\n          header\n          body\n        }\n      }\n      route {\n        id\n        shortName\n        longName\n        alerts {\n          id\n          header\n          body\n        }\n      }\n      trip {\n        id\n        headsign\n        alerts {\n          id\n          header\n          body\n        }\n      }\n      type: typeAsString\n      sortKey: departure\n      vehicle {\n        id\n        occupancyStatus\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetArrivals($station: ID, $stops: [ID]!, $limit: Int) {\n    arrivals(filter: { stops: $stops, station: $station }, limit: $limit) {\n      stop {\n        id\n        platformCode\n        alerts {\n          id\n          header\n          body\n        }\n      }\n      route {\n        id\n        shortName\n        longName\n        alerts {\n          id\n          header\n          body\n        }\n      }\n      trip {\n        id\n        headsign\n        alerts {\n          id\n          header\n          body\n        }\n      }\n      type: typeAsString\n      sortKey: departure\n      vehicle {\n        id\n        occupancyStatus\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query allTripsForRoute($route: String!, $forDate: String) {\n    routes(filter: { shortNameIn: [$route] }) {\n      shortName\n      longName\n      trips(forDate: $forDate) {\n        id\n        headsign\n        lastStop {\n          stop {\n            name\n          }\n        }\n        stops {\n          stop {\n            id\n            name\n          }\n          departure\n          departureDeltaFromScheduledSeconds\n          type\n          stopSequence\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query allTripsForRoute($route: String!, $forDate: String) {\n    routes(filter: { shortNameIn: [$route] }) {\n      shortName\n      longName\n      trips(forDate: $forDate) {\n        id\n        headsign\n        lastStop {\n          stop {\n            name\n          }\n        }\n        stops {\n          stop {\n            id\n            name\n          }\n          departure\n          departureDeltaFromScheduledSeconds\n          type\n          stopSequence\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ConstructPageTitle($requestedStops: [ID]!) {\n    stops(filter: { idIn: $requestedStops }) {\n      id\n      name\n    }\n    stations(filter: { idIn: $requestedStops }) {\n      name\n    }\n  }\n"): (typeof documents)["\n  query ConstructPageTitle($requestedStops: [ID]!) {\n    stops(filter: { idIn: $requestedStops }) {\n      id\n      name\n    }\n    stations(filter: { idIn: $requestedStops }) {\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query allTripsForStop($stop: ID, $forDate: String) {\n    stops(filter: { idIn: [$stop] }) {\n      name\n      arrivals(lookbackSeconds: 86400, limit: 0, forDate: $forDate) {\n        trip {\n          id\n          headsign\n          stops {\n            stop {\n              name\n              id\n            }\n            departure\n            type\n          }\n        }\n        route {\n          shortName\n          longName\n        }\n        departure\n        departureDeltaFromScheduledSeconds\n        type\n      }\n    }\n  }\n"): (typeof documents)["\n  query allTripsForStop($stop: ID, $forDate: String) {\n    stops(filter: { idIn: [$stop] }) {\n      name\n      arrivals(lookbackSeconds: 86400, limit: 0, forDate: $forDate) {\n        trip {\n          id\n          headsign\n          stops {\n            stop {\n              name\n              id\n            }\n            departure\n            type\n          }\n        }\n        route {\n          shortName\n          longName\n        }\n        departure\n        departureDeltaFromScheduledSeconds\n        type\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetWeather {\n    weather {\n      temp\n      description\n    }\n  }\n"): (typeof documents)["\n  query GetWeather {\n    weather {\n      temp\n      description\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query dateRangeForSchedule {\n    agencies {\n      schedule {\n        fromDate\n        untilDate\n      }\n    }\n  }\n"): (typeof documents)["\n  query dateRangeForSchedule {\n    agencies {\n      schedule {\n        fromDate\n        untilDate\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query allRoutes {\n    routes(limit: 0) {\n      shortName\n      longName\n    }\n  }\n"): (typeof documents)["\n  query allRoutes {\n    routes(limit: 0) {\n      shortName\n      longName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAllStopsAndStations {\n    stops(limit: 10000) {\n      value: id\n      label: name\n      location {\n        latitude\n        longitude\n      }\n      parentStation\n    }\n    stations(limit: 10000) {\n      value: id\n      label: name\n      location {\n        latitude\n        longitude\n      }\n      parentStation\n    }\n  }\n"): (typeof documents)["\n  query GetAllStopsAndStations {\n    stops(limit: 10000) {\n      value: id\n      label: name\n      location {\n        latitude\n        longitude\n      }\n      parentStation\n    }\n    stations(limit: 10000) {\n      value: id\n      label: name\n      location {\n        latitude\n        longitude\n      }\n      parentStation\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;